import SphericalMercator from '@mapbox/sphericalmercator';
import { scale, rotate, compose, applyToPoints } from 'transformation-matrix';
import {
  center as turfCenter,
  polygon as turfPolygon,
  lineString as turfLineString,
  bearing as turfBearing
} from '@turf/turf';
import parseGeoraster from 'georaster';
import ApiService from '@/services/ApiService';

export function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

export default class {
  constructor() {
    this.tl = undefined;
    this.tr = undefined;
    this.br = undefined;
    this.bl = undefined;
    this.top = undefined;

    this.fixP = undefined;
    this.movingP = undefined;
    this.oWidth = undefined;
    this.oHeight = undefined;
    this.oBearing = undefined;

    this.sm = new SphericalMercator();
    this.ZOOM = 20;

    this.georefFixpoint = undefined;
  }

  initGeoref(coords) {
    this.georefFixpoint = this.toPixelCoord([coords[0][0], coords[0][1]]);
  }

  initCornerMove(coordinates, fixPoint, movingPoint) {
    this.tl = this.toPixelCoord(coordinates[0]);
    this.tr = this.toPixelCoord(coordinates[1]);
    this.br = this.toPixelCoord(coordinates[2]);
    this.bl = this.toPixelCoord(coordinates[3]);
    this.top = this.getTop(coordinates);

    this.fixP = this.toPixelCoord(fixPoint);
    this.movingP = this.toPixelCoord(movingPoint);

    this.oWidth = Math.abs(this.tl[0] - this.br[0]);
    this.oHeight = Math.abs(this.tl[1] - this.br[1]);
    this.oBearing = Math.atan2(this.movingP[1] - this.fixP[1], this.movingP[0] - this.fixP[0]);
  }

  initTopMove(coordinates) {
    this.tl = this.toPixelCoord(coordinates[0]);
    this.tr = this.toPixelCoord(coordinates[1]);
    this.br = this.toPixelCoord(coordinates[2]);
    this.bl = this.toPixelCoord(coordinates[3]);
    this.top = this.getTop(coordinates);

    this.oWidth = Math.abs(this.tl[0] - this.br[0]);
    this.oHeight = Math.abs(this.tl[1] - this.br[1]);
    this.oBearing = Math.atan2(this.tl[1] - this.br[1], this.tl[0] - this.br[0]);
  }

  initRotation(coordinates, fixPoint, movingPoint) {
    this.tl = this.toPixelCoord(coordinates[0]);
    this.tr = this.toPixelCoord(coordinates[1]);
    this.br = this.toPixelCoord(coordinates[2]);
    this.bl = this.toPixelCoord(coordinates[3]);
    this.top = this.getTop(coordinates);

    this.fixP = this.toPixelCoord(fixPoint);
    this.movingP = this.toPixelCoord(movingPoint);

    this.oWidth = Math.abs(this.tl[0] - this.br[0]);
    this.oHeight = Math.abs(this.tl[1] - this.br[1]);
    this.oBearing = Math.atan2(this.movingP[1] - this.fixP[1], this.movingP[0] - this.fixP[0]);
  }

  toPixelCoord(coordinate) {
    return this.sm.px(coordinate, this.ZOOM);
  }

  toMercatorCoord(px) {
    return this.sm.ll(px, this.ZOOM);
  }

  getCenter(coordinates) {
    return turfCenter(turfPolygon([[...coordinates, coordinates[0]]])).geometry.coordinates;
  }

  getTop(coordinates) {
    return turfCenter(turfLineString([coordinates[0], coordinates[1]])).geometry.coordinates;
  }

  getBearing(coordinates) {
    return turfBearing(coordinates[2], coordinates[1]);
  }

  calcScalingCoords(proportion) {
    let matrix = compose(scale(proportion, proportion, this.fixP[0], this.fixP[1]));
    let [tl, tr, br, bl] = applyToPoints(matrix, [this.tl, this.tr, this.br, this.bl]);
    if (this.georefFixpoint) {
      [this.georefFixpoint] = applyToPoints(matrix, [this.georefFixpoint]);
    }

    return [
      this.toMercatorCoord(tl),
      this.toMercatorCoord(tr),
      this.toMercatorCoord(br),
      this.toMercatorCoord(bl)
    ];
  }

  calcCornerMoveCoords(movingPoint) {
    let movingP = this.toPixelCoord(movingPoint);
    const distX = Math.abs(movingP[0] - this.fixP[0]);
    const distY = Math.abs(movingP[1] - this.fixP[1]);
    const newBearing = Math.atan2(movingP[1] - this.fixP[1], movingP[0] - this.fixP[0]);
    const angle = newBearing - this.oBearing;
    const newWidth = Math.abs(distY * Math.sin(angle)) + Math.abs(distX * Math.cos(angle));
    const newHeight = Math.abs(distX * Math.sin(angle)) + Math.abs(distY * Math.cos(angle));
    const scaleX = newWidth / this.oWidth;
    const scaleY = newHeight / this.oHeight;

    const minScale = scaleX < scaleY ? scaleX : scaleY;
    let matrix = compose(
      rotate(angle, this.fixP[0], this.fixP[1]),
      scale(minScale, minScale, this.fixP[0], this.fixP[1])
    );
    let [tl, tr, br, bl] = applyToPoints(matrix, [this.tl, this.tr, this.br, this.bl]);

    return [
      this.toMercatorCoord(tl),
      this.toMercatorCoord(tr),
      this.toMercatorCoord(br),
      this.toMercatorCoord(bl)
    ];
  }

  calcTopMoveCoords(newCoords) {
    let newPxCoords = this.toPixelCoord(newCoords);
    const distX = newPxCoords[0] - this.toPixelCoord(this.top)[0];
    const distY = newPxCoords[1] - this.toPixelCoord(this.top)[1];
    this.top = this.toMercatorCoord(newPxCoords);
    this.tl = [this.tl[0] + distX, this.tl[1] + distY];
    this.tr = [this.tr[0] + distX, this.tr[1] + distY];
    this.bl = [this.bl[0] + distX, this.bl[1] + distY];
    this.br = [this.br[0] + distX, this.br[1] + distY];

    return [
      this.toMercatorCoord(this.tl),
      this.toMercatorCoord(this.tr),
      this.toMercatorCoord(this.br),
      this.toMercatorCoord(this.bl)
    ];
  }

  calcRotationCoords(movingPoint) {
    let movingP = this.toPixelCoord(movingPoint);
    const newBearing = Math.atan2(movingP[1] - this.fixP[1], movingP[0] - this.fixP[0]);
    const angle = newBearing - this.oBearing;

    let matrix = compose(
      rotate(angle, this.fixP[0], this.fixP[1]),
      scale(1, 1, this.fixP[0], this.fixP[1])
    );
    let [tl, tr, br, bl] = applyToPoints(matrix, [this.tl, this.tr, this.br, this.bl]);

    return [
      this.toMercatorCoord(tl),
      this.toMercatorCoord(tr),
      this.toMercatorCoord(br),
      this.toMercatorCoord(bl)
    ];
  }

  rotateByAngle(coordinates, angle) {
    this.tl = this.toPixelCoord(coordinates[0]);
    this.tr = this.toPixelCoord(coordinates[1]);
    this.br = this.toPixelCoord(coordinates[2]);
    this.bl = this.toPixelCoord(coordinates[3]);
    this.fixP = this.toPixelCoord(this.getCenter(coordinates));

    const rad = angle * (Math.PI / 180);

    let matrix = compose(
      rotate(rad, this.fixP[0], this.fixP[1]),
      scale(1, 1, this.fixP[0], this.fixP[1])
    );

    let [tl, tr, br, bl] = applyToPoints(matrix, [this.tl, this.tr, this.br, this.bl]);

    if (this.georefFixpoint) {
      [this.georefFixpoint] = applyToPoints(matrix, [this.georefFixpoint]);
    }

    return [
      this.toMercatorCoord(tl),
      this.toMercatorCoord(tr),
      this.toMercatorCoord(br),
      this.toMercatorCoord(bl)
    ];
  }

  translate(coordinates, referencePoint) {
    this.tl = this.toPixelCoord(coordinates[0]);
    this.tr = this.toPixelCoord(coordinates[1]);
    this.br = this.toPixelCoord(coordinates[2]);
    this.bl = this.toPixelCoord(coordinates[3]);

    let newPxCoords = this.toPixelCoord(referencePoint);
    const distX = newPxCoords[0] - this.georefFixpoint[0];
    const distY = newPxCoords[1] - this.georefFixpoint[1];

    this.tl = [this.tl[0] + distX, this.tl[1] + distY];
    this.tr = [this.tr[0] + distX, this.tr[1] + distY];
    this.bl = [this.bl[0] + distX, this.bl[1] + distY];
    this.br = [this.br[0] + distX, this.br[1] + distY];
    this.georefFixpoint = undefined;

    return [
      this.toMercatorCoord(this.tl),
      this.toMercatorCoord(this.tr),
      this.toMercatorCoord(this.br),
      this.toMercatorCoord(this.bl)
    ];
  }

  async getImageDimensionsFromFile(file) {
    let onload2promise = function(obj) {
      return new Promise((resolve, reject) => {
        obj.onload = () => resolve(obj);
        obj.onerror = reject;
      });
    };

    let img = new Image();
    let imgPromise = onload2promise(img);
    let url = URL.createObjectURL(file);
    img.src = url;
    await imgPromise;
    URL.revokeObjectURL(img.src);

    return {
      height: img.height,
      width: img.width
    };
  }

  async getImageMeta({ file }) {
    let img, mimeType;
    try {
      if (file.type === 'image/tiff') {
        let contentBuffer = await readFileAsync(file);
        img = await parseGeoraster(contentBuffer);
        const { data } = await ApiService.imageConvert(file);
        mimeType = 'image/jpeg';
        file = new Blob([data]);
        return {
          file,
          mimeType,
          height: img.height,
          width: img.width,
          projection: img.projection,
          corners: [
            [img.xmin, img.ymax],
            [img.xmax, img.ymax],
            [img.xmax, img.ymin],
            [img.xmin, img.ymin]
          ]
        };
      } else {
        mimeType = file.type;
        img = new Image();
        img.src = URL.createObjectURL(file);
        await img.decode();
        URL.revokeObjectURL(img.src);
        return {
          file,
          mimeType,
          height: img.height,
          width: img.width
        };
      }
    } catch {
      // ignore errors
    }
  }

  async getCornersAndMimeType({ tl, br, file }) {
    const tlPx = this.toPixelCoord(tl);
    const brPx = this.toPixelCoord(br);
    const maxWidth = Math.abs(tlPx[0] - brPx[0]);
    const maxHeight = Math.abs(tlPx[1] - brPx[1]);

    let img = await this.getImageMeta({ file });

    if (img.corners) {
      if (img.projection !== 4326) {
        if (img.projection === 23700) {
          // EOV
          for (let i in img.corners) {
            const { data: wgs84 } = await ApiService.coordinateConvert(
              Number(img.projection),
              img.corners[i].reverse()
            );
            img.corners[i] = [wgs84.result.wgs84.latitude, wgs84.result.wgs84.longitude];
          }
        }
      }
      return {
        image: img.file,
        coordinates: img.corners,
        mimeType: img.mimeType
      };
    }

    const scaleX = maxWidth / img.width;
    const scaleY = maxHeight / img.height;
    const minScale = scaleX < scaleY ? scaleX : scaleY;

    let matrix = scale(minScale, minScale, tlPx[0], tlPx[1]);
    let toTransform = [
      [tlPx[0] + img.width, tlPx[1]], // tl
      [tlPx[0] + img.width, tlPx[1] + img.height], // br
      [tlPx[0], tlPx[1] + img.height] // bl
    ];
    toTransform = applyToPoints(matrix, toTransform);

    return {
      image: img.file,
      coordinates: [
        this.toMercatorCoord(tlPx),
        this.toMercatorCoord(toTransform[0]),
        this.toMercatorCoord(toTransform[1]),
        this.toMercatorCoord(toTransform[2])
      ],
      mimeType: img.mimeType
    };
  }
}
