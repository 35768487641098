import i18n from '../i18n';
import constants from '~/shared/constants';

export const required = (value) => !!value || value === 0 || i18n.t('This field is required!');
export const email = (value) =>
  /^$/.test(value) ||
  /[^@]+@[^@]+\.[^@]+/.test(value) ||
  i18n.t('The email address format is not correct!');
export const password = (value) =>
  (!!value && value.length > 5) || i18n.t('The password must be at least 6 characters long!');
export const imageSize = (value) => {
  return !value || (!!value && value.size < constants.IMAGE_SIZE_LIMIT) || i18n.t('Image too big!');
};
export const noSlashOnEnd = (value) => {
  return (
    !value || !value.endsWith('/') || i18n.t('The webhook URL should not end with a slash (/)!')
  );
};

export const positiveNumber = (value) => {
  const num = Number(value);
  return num > 0 || 'Enter a positive number!';
};
