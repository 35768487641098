// mixin to define rules in one place and dynamically update them when the locale is changed

import {
  required,
  email,
  password,
  imageSize,
  positiveNumber,
  noSlashOnEnd
} from '../helpers/rules';

const mixin = {
  data() {
    return {
      isLocaleChanging: false
    };
  },

  computed: {
    rules() {
      // disable the rules while changing, then re-enable them
      return this.isLocaleChanging
        ? { required: true }
        : { required, email, password, imageSize, positiveNumber, noSlashOnEnd };
    }
  },

  watch: {
    '$i18n.locale'() {
      this.isLocaleChanging = true;
      this.$nextTick(() => (this.isLocaleChanging = false));
    }
  }
};

export default mixin;
